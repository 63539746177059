import * as React from "react";

interface SectionProps {
  id?: string;
  header?: React.ReactElement;
  footer?: React.ReactElement;
  className?: string;
}

export default function Section({
  id,
  header,
  footer,
  children,
  className = "",
}: React.PropsWithChildren<SectionProps>) {
  return (
    <section
      id={id}
      className={`flex min-h-screen flex-col justify-between ${className}`}
    >
      <header className={"flex h-24"}>{header}</header>
      <main className={"py-8 md:py-4 flex-grow flex items-center"}>
        {children}
      </main>
      <footer className={"flex h-32 md:h-24"}>{footer}</footer>
    </section>
  );
}
