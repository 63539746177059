import * as React from "react";
import { Link } from "gatsby";
import { NavBarLinks } from "../nav-bar";

export function NavBarToggle({
  onClick,
}: {
  onClick: React.EventHandler<any>;
}) {
  const genericHamburgerLine = `h-1 w-6 my-1 rounded-full bg-black transition ease transform duration-300`;
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(!isOpen);

    onClick(event);
  };

  return (
    <button
      className="flex lg:hidden flex-col h-12 w-6 justify-center items-center"
      onClick={toggleMenu}
    >
      <div
        className={`${genericHamburgerLine} ${
          isOpen ? "rotate-45 translate-y-3" : ""
        }`}
      />
      <div className={`${genericHamburgerLine} ${isOpen ? "opacity-0" : ""}`} />
      <div
        className={`${genericHamburgerLine} ${
          isOpen ? "-rotate-45 -translate-y-3" : ""
        }`}
      />
    </button>
  );
}

interface NavBarMobileProps {
  showMenu: boolean;
}

export default function NavBarMobile({ showMenu }: NavBarMobileProps) {
  const navBarClass = (path: string, index: number) => {
    const currentPath =
      typeof window !== "undefined" ? window.location.pathname : "";

    if (path === currentPath) return "bg-white";

    return index % 2 === 0 ? "bg-primary-dark" : "";
  };

  return (
    <div className="w-full relative lg:hidden">
      <ul
        className={`transition-all ease-out z-30 absolute mt-4 w-full bg-primary ${
          showMenu ? "h-72" : "h-0"
        } overflow-hidden`}
      >
        {NavBarLinks.map((link, index) => {
          const linkClass = navBarClass(link.href, index);

          return (
            <li key={`navbar-${index}`}>
              <Link
                className={`block uppercase font-semibold focus:bg-white hover:bg-white text-black px-5 py-2 text-2xl ${linkClass}`}
                to={link.href}
              >
                {link.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
