import * as React from "react";
import Container from "../container";
import useSiteMetadata from "../../hooks/use-site-metadata";
import NavBar from "../nav-bar";
import NavBarMobile, { NavBarToggle } from "../nav-bar-mobile";
import Logo from "../logo";

export default function Header() {
  const { siteUrl } = useSiteMetadata();
  const [showMenu, setShowMenu] = React.useState(false);

  return (
    <header
      className={"absolute h-24 top-0 flex flex-col justify-center w-full"}
    >
      <Container
        breakpoint={"xl"}
        className={"w-full justify-between items-center"}
      >
        <NavBarToggle onClick={() => setShowMenu(!showMenu)} />
        <div className={"w-1/2 flex justify-center lg:w-auto"}>
          <a href={siteUrl}>
            <Logo width={"275px"} />
          </a>
        </div>
        <NavBar />
      </Container>
      <Container>
        <NavBarMobile showMenu={showMenu} />
      </Container>
    </header>
  );
}
