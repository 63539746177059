import * as React from "react";
import { Link } from "gatsby";

interface NavBarLink {
  name: string;
  href: string;
}

export const NavBarLinks: NavBarLink[] = [
  {
    name: "Our Story",
    href: "/our-story/",
  },
  {
    name: "Menu",
    href: "/menu/",
  },
  {
    name: "Book",
    href: "/book/",
  },
  {
    name: "Gift Vouchers",
    href: "/gift-vouchers/",
  },
  {
    name: "Contact",
    href: "/contact/",
  },
];

export default function NavBar() {
  const navBarClass = (path: string) => {
    const currentPath =
      typeof window !== "undefined" ? window.location.pathname : "";

    return path === currentPath ? "bg-primary px-2" : "";
  };

  return (
    <ul className={"gap-8 hidden lg:flex"}>
      {NavBarLinks.map((link, index) => {
        const linkClass = navBarClass(link.href);

        return (
          <li
            className={
              "uppercase font-semibold hover:underline text-black text-2xl"
            }
            key={`navbar-${index}`}
          >
            <Link className={linkClass} to={link.href}>
              {link.name}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
