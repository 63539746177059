import * as React from "react";

interface SpinnerProps {
  size?: "small" | "medium" | "large";
  className?: string;
}

export default function Spinner({ className = "", size }: SpinnerProps) {
  const sizes = {
    small: "h-8 w-8",
    medium: "h-16 w-16",
    large: "h-32 w-32",
  };

  return (
    <div className={`flex justify-center items-center ${className}`}>
      <div
        className={`animate-spin rounded-full border-b-2 border-gray-900 ${
          sizes[size ?? "small"]
        }`}
      />
    </div>
  );
}
