import * as React from "react";
import { Helmet } from "react-helmet";
import SimpleReactLightbox from "simple-react-lightbox";
import Header from "../header";
import Footer from "../footer";
import VipSignupPopup from "../vip-signup-popup";
import useSiteMetadata from "../../hooks/use-site-metadata";
import layoutBg from "../../images/bg.jpg";
import ogImageDefault from "../../images/og-image.jpg";

type LayoutProps = {
  className?: string;
  title?: string;
  description?: string;
  ogType?: string;
  ogImage?: string;
  ogImageHeight?: string;
  ogImageWidth?: string;
  children: React.ReactNode;
};

export default function Layout({
  children,
  title,
  description,
  ogImage,
  ogType = "website",
  ogImageWidth = "1200",
  ogImageHeight = "600",
  className = "",
}: LayoutProps) {
  const metadata = useSiteMetadata();

  return (
    <SimpleReactLightbox>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title ? `${title} | ${metadata.title}` : metadata.title}</title>
        <meta
          name="description"
          content={description ?? metadata.description}
        />
        <meta property="og:image" content={ogImage ?? ogImageDefault} />
        <meta property="og:image:height" content={ogImageHeight} />
        <meta property="og:image:width" content={ogImageWidth} />
        <meta property="og:type" content={ogType} />

        <link rel="canonical" href={metadata.siteUrl} />
        <meta name="twitter:title" content={title ?? metadata.title} />
        <meta
          name="twitter:description"
          content={description ?? metadata.description}
        />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <div
        className={`layout ${className} flex min-h-screen relative flex-col justify-between`}
        style={{ backgroundImage: `url(${layoutBg})` }}
      >
        <Header />

        <main>{children}</main>

        <Footer />
        <VipSignupPopup />
      </div>
    </SimpleReactLightbox>
  );
}
