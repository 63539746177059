import * as React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

interface ModalProps {
  trigger?: React.ReactElement;
  cookieName?: string;
  isOpen?: boolean;
  onClose?: () => void;
}

export default function Modal({
  children,
  isOpen = false,
  onClose,
  trigger,
}: React.PropsWithChildren<ModalProps>) {
  const [localOpenControl, setLocalOpenControl] = React.useState(true);
  const closeModal = () => {
    setLocalOpenControl(false);

    onClose && onClose();
  };

  return (
    <Popup
      className={"animate-on-open modal"}
      open={localOpenControl && isOpen}
      trigger={trigger}
      closeOnDocumentClick={false}
      onClose={closeModal}
      position="center center"
    >
      <div>
        <div className="text-right px-2 md:px-5">
          <button
            title={"Close Modal"}
            onClick={closeModal}
            className="text-4xl transition-colors focus:outline-none hover:text-primary-dark"
          >
            &times;
          </button>
        </div>
        <div className={"px-2 pb-2 md:px-5 md:pb-5"}>{children}</div>
      </div>
    </Popup>
  );
}
