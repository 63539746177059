import * as React from "react";
import Modal from "../modal";
import Spinner from "../spinner";

export default function VipSignupPopup() {
  const cookieName = "vip-signup";
  const mailchimpUrl =
    "https://eddyandwolff.us10.list-manage.com/subscribe/post";
  const popupTimeoutInSeconds = 3;
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState<
    "initial" | "sending" | "error" | "sent"
  >("initial");
  const recordClose = () => {
    localStorage.setItem(cookieName, "1");
  };

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.currentTarget;
    const formData = new FormData(form);

    const safeFormData = new FormData();

    safeFormData.set("MERGE0", String(formData.get("email")));
    safeFormData.set("u", "fed48e5a21a6272a8dae99333");
    safeFormData.set("id", "1997402e7f");
    safeFormData.set("mc_signupsource", "hosted");
    safeFormData.set(
      "ht",
      "512fe919f506aed73f7ea9e0f50320eb55e50c11:MTY4Mzg3NzA0MC4zNzE0"
    );

    const [day, month] = String(formData.get("DOB")).split("/");
    safeFormData.set("MERGE4[day]", day);
    safeFormData.set("MERGE4[month]", month);

    safeFormData.set("MERGE1", String(formData.get("FNAME")));
    safeFormData.set("MERGE2", String(formData.get("LNAME")));

    setStatus("sending");

    try {
      await fetch(form.action, {
        method: "POST",
        mode: "no-cors",
        body: safeFormData,
      });
      setStatus("sent");
    } catch (e) {
      console.debug(e);
      setStatus("error");
    }
  };

  React.useEffect(() => {
    if (!localStorage.getItem(cookieName)) {
      setTimeout(() => {
        setOpen(true);
      }, popupTimeoutInSeconds * 1000);
    }
  }, []);

  return (
    <div>
      <Modal isOpen={open} onClose={recordClose}>
        <div className={"text-center"}>
          <h1 className={"pb-5 text-4xl uppercase font-bold text-primary"}>
            Join the Wolff Pack
          </h1>
          <h3 className={"pb-5 text-xl"}>Be The First To Know Our Secrets</h3>
          <form action={mailchimpUrl} method={"post"} onSubmit={submitForm}>
            <input
              className="w-60 max-w-full block m-auto transition-colors focus:border-primary focus:ring-transparent outline-none placeholder-black mb-8 pb-2 border-b-2 border-black"
              type="email"
              name="email"
              placeholder="EMAIL"
              required={true}
            />

            <input
              className="w-60 max-w-full block m-auto transition-colors focus:border-primary focus:ring-transparent outline-none placeholder-black mb-8 pb-2 border-b-2 border-black"
              type="text"
              name="DOB"
              pattern={"[0-9]{2}/[0-9]{2}"}
              placeholder="BIRTHDAY (DD/MM)"
              required={true}
            />

            <input
              className="w-60 max-w-full block m-auto transition-colors focus:border-primary focus:ring-transparent outline-none placeholder-black mb-8 pb-2 border-b-2 border-black"
              type="text"
              name="FNAME"
              placeholder="FIRST NAME"
              required={true}
            />

            <input
              className="w-60 max-w-full block m-auto transition-colors focus:border-primary focus:ring-transparent outline-none placeholder-black mb-8 pb-2 border-b-2 border-black"
              type="text"
              name="LNAME"
              placeholder="LAST NAME"
              required={true}
            />

            {status !== "sent" && (
              <button
                type="submit"
                className={
                  "py-2 px-8 uppercase font-semibold border-4 inline-block bg-white border-black hover:bg-primary focus:bg-primary hover:border-primary focus:border-primary hover:text-white focus:text-white cursor-pointer"
                }
              >
                Subscribe
              </button>
            )}

            <div className={"pt-3"}>
              {(() => {
                switch (status) {
                  case "sending":
                    return <Spinner />;
                  case "error":
                    return (
                      <p className={"text-red-600"}>
                        There was an error. Try again later.
                      </p>
                    );
                  case "sent":
                    return (
                      <p className="text-primary-dark">
                        Thank you for subscribing!
                      </p>
                    );
                  default:
                    return null;
                }
              })()}
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
