import { useStaticQuery, graphql } from "gatsby";

export interface SiteMetadata {
  title: string;
  description: string;
  siteUrl: string;
  pageLinks: {
    home: string;
    ourStory: string;
    menu: string;
    book: string;
    giftVouchers: string;
    contact: string;
  };
  socialLinks: {
    facebook: string;
    instagram: string;
    phone: string;
    email: string;
  };
}

export default function useSiteMetadata(): SiteMetadata {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            socialLinks {
              facebook
              instagram
              phone
              email
            }
            pageLinks {
              home
              ourStory
              menu
              book
              giftVouchers
              contact
            }
          }
        }
      }
    `
  );
  return site.siteMetadata;
}
