import * as React from "react";
import { Link } from "gatsby";
import Container from "../container";
import bdfcLogo from "../../images/bonafidedesignco.png";

export default function Footer() {
  return (
    <footer
      className={"absolute h-32 md:h-24 flex items-center w-full bottom-0"}
    >
      <Container
        className={
          "justify-between items-center gap-4 flex-col-reverse sm:flex-row"
        }
      >
        <div>
          <a
            href={"https://bonafidedesignco.com"}
            target={"_blank"}
            rel="noreferrer"
          >
            <img src={bdfcLogo} alt={"Bona Fide Design Co Logo"} width={40} />
          </a>
        </div>
        <div
          className={
            "flex text-center gap-4 sm:text-left sm:gap-8"
          }
        >
          <p>Copyright &copy; {new Date().getFullYear()} All Rights Reserved</p>
          <p>
            <Link className={"hover:underline"} to={"/privacy-policy"}>Privacy Policy</Link>
          </p>
        </div>
      </Container>
    </footer>
  );
}
